const login = 'LOGIN';
const verify = 'VERIFY';
const logout = 'LOGOUT';
const initialState = { auth: false, 
    status: 'not pending', 
    email: null, 
    password: null };

export const actionCreators = {
  doLogin: (name, password) => ({ type: login, name, password }),
  doVerify: (auth) => ({ type: verify, auth }),
  doLogout: () => ({type: logout})
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === login) {
    return { ...state, email: action.email, password: action.password};
  }

  if (action.type === verify) {
    return { ...state, auth: action.auth};
  }

  if (action.type === logout) {
    return initialState;
  }

  return state;
};