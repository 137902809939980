const customer = 'CUSTOMER';
const pharmacy = 'PHARMACY';
const reset = 'RESET';
const toggleCustomer = 'TOGGLE_CUSTOMER';
const initialState = { customer_info: null,
    pharmacy_info: null,
    payment_info: null, 
    saveAdmin: {} };

export const actionCreators = {
  setCustomer: (cust) => ({ type: customer, cust }),
  setPharmacy: (pharm) => ({ type: pharmacy, pharm }),
  doReset: () => ({ type: reset }),
  toggleCustomer: (pharm) => ({ type: toggleCustomer, pharm }),
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === customer) {
    return { ...state, customer_info: action.cust};
  }

  if (action.type === pharmacy) {
    return { ...state, pharmacy_info: action.pharm};
  }

  if (action.type === reset) {
    return initialState
  }

  if (action.type === toggleCustomer) {
    if (action.pharm) {    
      return { ...state, saveAdmin: action.pharm };
    }
    return { ...state, saveAdmin: {} };
  }

  return state;
};