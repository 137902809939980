import React, { Component } from 'react';
import { connect } from 'react-redux';

// const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const MainContainer = React.lazy(() => import('./pages/MainContainer'));
const HomeContainer = React.lazy(() => import('./pages/HomeContainer'));

class App extends Component {
  render() {
    return this.props.auth ? <MainContainer /> : <HomeContainer />
  }
}

export default connect(
  state => state.auth
)(App);