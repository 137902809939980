const toggleMenu = 'TOGGLE_MENU';
const sideMenuSelect = 'SIDE_SELECT';
const paymentSelect = 'PAYMENT_SELECT';
const accountSelect = 'ACCOUNT_SELECT';
const adminSelect = 'ADMIN_SELECT';
const resetMenu = 'RESET_MENU';
const toggleModal = 'TOGGLE_MODAL';
const initialState = { smallMenu: false, 
  activeItemSide: 'dashboard',
  activeItemPay: 'methods',
  activeItemAccount: 'billing',
  activeItemAdmin: 'customers',
  pwModalOpen: false };

export const actionCreators = {
  toggleSideMenu: () => ({ type: toggleMenu }),
  sideMenuSelectItem: (item) => ({ type: sideMenuSelect, item }),
  paymentSelectItem: (item) => ({ type: paymentSelect, item }),
  accountSelectItem: (item) => ({ type: accountSelect, item }),
  adminSelectItem: (item) => ({ type: adminSelect, item }),
  doResetMenu: () => ({ type: resetMenu }),
  toggleModal: () => ({ type: toggleModal }),
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === toggleMenu) {
    return { ...state, smallMenu: !state.smallMenu};
  }

  if (action.type === sideMenuSelect) {
    return { ...state, activeItemSide: action.item};
  }

  if (action.type === paymentSelect) {
    return { ...state, activeItemPay: action.item};
  }

  if (action.type === accountSelect) {
    return { ...state, activeItemAccount: action.item};
  }

  if (action.type === adminSelect) {
    return { ...state, activeItemAdmin: action.item};
  }

  if (action.type === toggleModal) {
    return { ...state, pwModalOpen: !state.pwModalOpen};
  }

  if (action.type === resetMenu) {
    return initialState
  }

  return state;
};